#contact {
    width: 100%;
    background-color: var(--lighter-blue);
}

.contactDescription {
    margin-top: 0.5rem;
}

form {
    color: black;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.1rem;
}

.container-horizontal-spaced {
    display: flex;
    justify-content: space-between;
}

#contactRightContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.25rem;
}

#contactRightContainer div {
    width: 60%;
    text-align: center;
    
}

#contactForm {
    width: 50%;
    min-width: 25rem;
}

@media (max-width: 900px) {
    #contactForm {
        min-width: initial;
    }

    .container-horizontal-spaced{
        flex-direction: column-reverse;
    }
    #contactRightContainer {
        padding-left: 0px;
        width: 100%;
        font-size: 1rem;
    }
    #contactForm {
        width: 100%;
    }
    #contactRightContainer div {
        width: 100%;
    }
}


form div {
    margin-top: 1.5rem;
    display: flex;
}

#submit-info-span-container {
    display: flex;
    flex-wrap:wrap;
}
#form-info-span {
    font-size: 0.8rem;
    margin-left: auto;
    font-weight: lighter;
}

#form-info-span.loading {

}


#form-info-span.success {
    color:aquamarine;
}

#form-info-span.error {
    color: #ff3535;
}

#form-info-span.hidden {
    display: none;
}



input, 
textarea {
    color: white;
    font-size: 1.25rem;
    margin-top: 0.25rem;
    margin-right: 0px;
    width: 100%;
    padding: .75rem 0rem 0.75rem 1rem;
    background-color: var(--contact-form-input-color);
    resize: none;
    transition-duration: 0s;
    overflow: scroll;
}

input.default-border,
textarea.default-border {
    border: 2px solid var(--contact-form-input-color);
}

input.error-border,
textarea.error-border {
    border: 2px solid #ff3535;
}

input:hover,
textarea:hover,
input:focus,
textarea:focus {
    outline: none;
    border: 2px solid #517fec;
}

label {
    margin-top: 1.5rem;
}
input, label {
    display: block;
    font-family: "Poppins", sans-serif;
}

.button {
    padding: 0rem;
    letter-spacing: 0.1rem;
    color: black;
    font-family: "Poppins", sans-serif;
    font-size: 0.8rem;
    font-weight: bold;
    background-color: #517fec;
    border: 2px solid #517fec;
    width: 10rem;
    height: 2.5rem;
    max-width: 15rem;
    margin: 0rem;
    text-align: center;
}

.button:hover {
    color: white;
    background-color: transparent;
    cursor: pointer;
}