#about {
    background: var(--lighter-blue);
}


#aboutInnerContainer {
   
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 75rem;
    font-size: 1.5rem;
}

#aboutMeTextContainer {
    width: 30rem;
}

.mediumImage {
    width: 35rem;
    max-width: 80vw;
}

#aboutMeTextTitle {
    letter-spacing: 0.1rem;
    font-weight: bold;
}

#aboutMeTextTitleContainer {
    margin-bottom: 2rem;
}


@media (max-width: 1320px) {
    #aboutInnerContainer {
        text-align: center;
        flex-direction: column;
        align-items: center;
        gap: 3rem;
    }

    #aboutMeImage {
        width: 45rem;
    }

    #aboutContainer {
        width: 45rem;
        max-width: 80vw;
    }

    #aboutMeTextContainer {
        width: 100%;
        max-width: 45rem;
    }
}

@media (max-width: 900px) {
    #aboutInnerContainer {
        font-size: 1.2rem;
    }
}


