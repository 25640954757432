* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: clip;
  color: white;
  font-family: poppins, sans-serif;
  transition: all .2s ease-in-out;
}

form ::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #8e8e8e;
  border-radius: 2px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 40px;
}

::-webkit-scrollbar-thumb:active {
  background-color: white;
}

:root {
  overflow-y: scroll;
  scroll-behavior: smooth;
  --dark-blue: #1f1f2d;
  --lighter-blue: #232331;
  --contact-form-input-color: #2a2a3e;
  /* --dark-blue: #232331;
  --lighter-blue: #1f1f2d; */
}

body {
  background-color: var(--dark-blue);  
  /* overflow-y: scroll; */
}

.section {
  padding-top: 10rem;
  padding-bottom: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionContainer {
  width: 75rem;
}

@media (max-width: 1350px) {
  .sectionContainer {
    width: 80vw;
  }
}