#projects {
   
}



@media (max-width: 1350px) {
    #projectsContainer {
        width: 80vw;
    }
}

.projectTitleContainer {
    padding-bottom: 2rem;
}

.projectTitle {
    font-size: 1.2rem;
    letter-spacing: 0.3rem;
    color: white;
    font-weight:bold; 
}

.projectDescription {
    font-size: 1.2rem;
    line-height: 1.7rem;
}
.projectTechnologies {
    font-weight: bold;
    word-spacing: 1.5rem;
}

.projectExampleContainer a {
    text-decoration: underline;
}


.projectExampleContainer {
    
    overflow: auto;
    width: 100%;
    padding: 2rem;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
      
}

#projects .projectExampleContainer {
    border-top: 1px solid hsla(0, 0%, 100%, 0.35);
}

#projects .projectExampleContainer:first-child{
    border-top: none;
}

.projectImageContainer {
    text-align: center;
}


.projectDescriptionContainer {
    width: 16rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.projectImage {
    width: 35rem;
    /* height: 25rem; */
    min-width: 15rem;
    min-height: 10rem;
    transition: all .1s ease-in-out;
}
@media (max-width:1250px) {
    .projectImage {
      width: 25rem;
      height: 18rem;  
    }

   

}

@media (max-width: 975px){

    .projectExampleContainer {
        padding-left: 0;
        padding-right: 0;
        gap: 2rem;
    }

    .projectImage {
        width: 80vw;
        height: auto;
    }

    .projectExampleContainer.right {
        flex-direction: column-reverse;
    }

    .projectExampleContainer.left {
        flex-direction: column;
    }
}


.projectImage:hover {
    transform: scale(1.03);
    cursor: pointer;
}



