body {
  display: flex;
  flex-direction: column;
}

#topContainer {
  overflow: auto;
  width: 100%;
  display: flex;
  overflow-x: clip;
  flex-direction: column;
  min-height: 100vh;
  max-height: 150rem;
}


#squiggle {
  position: absolute;
  top: 5rem;
  left: 0;
  height: auto;
  width: 100%;
  z-index: -10;
  stroke-dasharray: 10000;
  stroke-dashoffset: 10000;
  animation: dash 3s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}



#nameAndTitleContainer {
  width: 70rem;
  max-width: 90vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#titleSectionContainer {
  max-width: 80vw;
}


#profilePicContainer {
  text-align: center;
}

@media (max-width: 1000px) {
  #nameAndTitleContainer {
    flex-direction: column-reverse;
    gap: 1.5rem;
    align-items: center;
    text-align: center;
    margin-top: 15vh;
  }

  #nameSubtitleContainer {
    margin: 0 auto;
    text-align: center;
  }

  #profilePicContainer {
    padding-left: 0px;
    text-align: center;
  }
    
}



#profilePic {
  background-image: url(../images/profilepic.webp);
  /* background-color: black; */
  background-position: 50% 50%;
  background-size: 20rem;
  animation: warp 8s ease-in-out infinite;
  animation-iteration-count: infinite;
  display: inline-block;
  width: 20rem;
  height: 20rem;

  border: 3px solid white;
}

@media (max-width: 500px ) {
  #profilePic {
    width: 15rem;
    height: 15rem;
    background-size: 15rem;
  }
}

@keyframes warp {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%
  }
  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}


#nameTitle {
  font-size: 4.5rem;
  line-height: 4rem;
}
#nameSubtitleContainer {
  margin-top: 1rem;
  width: 30rem;
  max-width: 70vw;
}

#nameSubtitle {
  width: 20rem;
  font-size: 1.5rem;
  opacity: 0.8;
}


@media (max-width:1200px) {
  #nameTitle {
    font-size: 3.5rem;
  }

  #nameSubtitle {
    font-size: 1rem;
  }
}

@media (max-width:450px) {
  #nameTitle {
    font-size: 2.5rem;
  }

}

#externalsContainer {
  justify-content: space-around;
  width: 100%;
  margin: auto;
  margin-top: 2vh;

}




  