.icon {
    /* padding: 2px; */
    height: 2rem;
    max-width: 2rem;
    fill: lightgray;
    margin-bottom:auto;
    transition: all .05s ease-in-out;
}

#github.icon {
    padding: 2px;
}

@media (max-width: 400px) {
    .icon {
        height: 1rem;
        max-width: 1rem;
    }
}


.icon:hover {
    
    transform: scale(1.1);
    fill: white;
}



.mediaLink {
    text-align-last: center;
    justify-content: space-around;
}

.bounce {
    animation: bounce 2s;
    animation-iteration-count: infinite;
}

@keyframes bounce {
    0%,
    25%,
    50%,
    75%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-20px);
    }

    60% {
        transform: translateY(-12px);
    }
}

