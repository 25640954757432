

#experience {
    text-align: center;
    align-items: center;
    width: 100%;
}

.experienceExampleContainer {
    display: flex;
}


.experienceTitleContainer {
    padding-right: 1rem;
    border-right: 1px solid white;
    margin-bottom: 1rem;
    width: 30rem;
    max-width: 70vw;
}
@media (max-width: 1000px) {
    .experienceExampleContainer {
        display: block;
    }

    .experienceTitleContainer {
        border: none;
    }

}

.experienceTitle {
    font-size: 2rem;
}

.experienceDescriptionContainer p {
    font-size: 1.5rem;
}

@media (max-width: 600px) {
    .experienceTitle {
        font-size: 1.2rem;
    }

    .experienceDescriptionContainer p {
        font-size: 1rem;
    }
}



#exampleDownArrowContainer {
    text-align: center;
}
